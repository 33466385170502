import { Container, Row, Col } from "react-bootstrap"
import logo from '../assets/img/name.png';

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="navbar-text">
                    <Col sm={6}>
                        <a href="#home">
                        <img src={logo} alt="Ron Madan" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}