import { useState, useEffect } from "react"
import { Navbar, Container, Nav } from "react-bootstrap"
import logo from '../assets/img/name.png';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('');
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true)
            }else {
                setScrolled(false)
            }
        }

        window.addEventListener("scroll", onScroll)

        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

    return (
        <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
            <Container>
                <Navbar.Brand href="#home">
                    <img src={logo} alt="Ron Madan" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" >
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link 
                        href="#home" 
                        className={activeLink === 'home' ? 'active-navbar-link' : 'navbar-link'}>
                            Home
                        </Nav.Link>
                        <Nav.Link 
                        href="#skills" 
                        className={activeLink === 'skills' ? 'active-navbar-link' : 'navbar-link'}>
                            Skills
                        </Nav.Link>
                        <Nav.Link 
                        href="#project" 
                        className={activeLink === 'projects' ? 'active-navbar-link' : 'navbar-link'}>
                            Projects
                        </Nav.Link>
                    </Nav>
                    <span className="navbar-text">
                        <div className="social-icon">
                            <a href="https://www.LinkedIn.com/in/Raunak-Madan" target="_blank"><img src={navIcon1} alt="" /></a>
                        </div>
                        <div className="github">
                            <a href="https://www.Github.com/Ron-Madan/Portfolio" target="_blank"><img src={navIcon2} alt="" /></a>
                        </div>
                        <a href = "mailto: Ron.Madan@mail.utoronto.ca">
                        <button className="vvd">
                            <span>Let's Connect!</span>
                        </button>
                        </a>
                    </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}